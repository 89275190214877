import { render, staticRenderFns } from "./ResultPageByDataPoints.vue?vue&type=template&id=43036b7c&scoped=true&"
import script from "./ResultPageByDataPoints.vue?vue&type=script&lang=js&"
export * from "./ResultPageByDataPoints.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43036b7c",
  null
  
)

export default component.exports